import React, { useState, useCallback, useEffect } from "react";
import { Heading, Popover, Button, ButtonGroup, Stack, Badge, Link, List, ActionList, Page, Card, Spinner, Layout } from "@shopify/polaris";
import axios from 'axios'
import { AppProvider } from "@shopify/polaris";
import _get from 'lodash/get';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT || "https://apinew.optinly.com/v1"
export default function Index(props = {}) {

  const [shop, setShop] = useState("");
  const [clientToken, setClientToken] = useState("");
  const [active, setActive] = useState(false);
  const [userThemes, setUserThemes] = useState([]);
  const [activeTheme, setActiveTheme] = useState([]);
  const [themeSupports, setThemeSupport] = useState(false);
  // const isDev = process.env.NODE_ENV === 'development';

  const [loading, setLoading] = useState();
  const [loadingSetup, setLoadingSetup] = useState(1);
  const [message, setMessage] = useState(null);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const getToken = _ => window.optinly_client_token || clientToken;
  const headers = {
    "authorization": getToken()
  }

  if (headers.authorization) delete headers.authorization;

  const auth = async (cb) => {
    axios.post(`${BASE_URL}/shopify/client-app/auth` + window.location.search)
      .then(response => {
        setLoading(0);
        const token = _get(response, "data.token");

        setClientToken(token)

        window.optinly_client_token = token;

        let rrl = _get(response, "data.redirect");
        if (rrl) window.location.replace(rrl)
        else cb && cb()
      })
      .catch(error => {
        console.log(error)
        setLoading(0);
        setMessage('Failed to login to the application. Please try again.')
      })
  }

  const handleImportedAction = function (e) {
    setActiveTheme(e)
    toggleActive()
  }


  const fetchThemes = async function () {
    setLoadingSetup(true)
    let search = window.location.search + `&is_app=true`
    axios.get(`${BASE_URL}/shopify/client-app/setup` + search, { headers: { "authorization": getToken() } })
      .then(d => d.data)
      .then(data => {

        let active_theme = _get(data, "main_theme") || {};
        active_theme.content = active_theme.name;

        let themes = _get(data, "themes") || [];

        themes = themes.map(i => {
          i.onAction = function () { handleImportedAction(i) };
          i.content = i.name;
          return i;
        });

        setShop(data.shop)
        setUserThemes(themes);
        setLoadingSetup(false);
        setActiveTheme(active_theme);
        setThemeSupport(data.support_theme_extension)
      }).catch(er => {
        console.log(er)
        setLoadingSetup(false)
        setUserThemes([]);
      })
  }

  useEffect(() => {
    auth(fetchThemes)
  }, [])


  const activator = (
    <Button onClick={toggleActive} disclosure="down">
      {_get(activeTheme, "name")}
    </Button>
  );

  const [showVideo, setShowVideo] = useState(false);

  const handlePreivewClick = () => {
    window.open(`https://${shop}/admin/themes/${_get(activeTheme, "id")}/editor?context=apps&activateAppId=${process.env.REACT_APP_EXTENSION_UUID}/optinly-embed`)
  }


  if (loading || loadingSetup)
    return (
      <div className='spin-container' style={{ height: "calc(60vh - 100px)", display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
        <Layout.Section>
          <Stack distribution="center">
            <Spinner />
          </Stack>
        </Layout.Section>
      </div>
    );
  else if (message)
    return <div style={styles.box}>{message}</div>
  else
    return (
      <AppProvider>

        <Page>
          <Layout>
            <Layout.Section></Layout.Section>
            <Layout.Section></Layout.Section>
            <Layout.Section></Layout.Section>
            <Layout.Section></Layout.Section>
          </Layout>
          <Card>
            <Card.Section>
              <Heading>Optinly Setup Instructions {themeSupports && <Badge status="info">Online Store 2.0</Badge>}</Heading>
            </Card.Section>

            <Card.Section>

              <Layout>
                <Layout.Section>

                  <List type="number">
                    <List.Item>Open the theme you’d like to customize</List.Item>
                    <List.Item>Click <strong>Theme Settings</strong>  at the bottom</List.Item>
                    <List.Item>Choose <strong>App Embeds</strong></List.Item>
                    <List.Item><strong>Enable</strong> Optinly </List.Item>
                  </List>

                </Layout.Section>

                <Layout.Section>

                </Layout.Section>

                {loadingSetup &&
                  <Layout.Section>
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <div style={{ margin: "2em 1em" }}></div>{" "}<Spinner size='small' />
                    </div>
                  </Layout.Section>
                }

                {themeSupports && !loadingSetup &&
                  <Layout.Section>
                    {/* <h4><strong>Select a theme to add Optinly script</strong></h4>
                      <div style={{ margin: "12px" }}></div>
                      <ButtonGroup>
                        <Popover
                          active={active}
                          activator={activator}
                          autofocusTarget="first-node"
                          onClose={toggleActive}
                        >
                          <ActionList
                            actionRole="menuitem"
                            items={userThemes}
                          />
                        </Popover>
                      </ButtonGroup> */}

                    <Layout.Section>
                    </Layout.Section>

                    <Card.Section>
                      <Button primary onClick={handlePreivewClick}>Preview in theme</Button>
                    </Card.Section>
                  </Layout.Section>
                }

                <Layout.Section>
                </Layout.Section>
                {
                  <Layout.Section>
                    <p>
                      Go to <Link url="https://app.optinly.com" external>Optinly dashboard</Link> for live your campaigns
                    </p>
                  </Layout.Section>
                }

              </Layout>
            </Card.Section>
          </Card>

        </Page>
      </AppProvider>
    );
}

const styles = {
  box: {
    padding: 10,
    width: 500,
    margin: 'auto',
    textAlign: 'center',
    marginTop: 100,
    fontWeight: 'bold'
  }
}